/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.page-wrap {
  min-height: 100vh;
}
.wrapper__section {
  background-color: white;
  border-radius: 8px;
  margin: 16px 0;
  position: relative;
}
.navbar-link {
  font-size: 16px;
  line-height: 2;
  padding: 0 24px;
}
.dropdown-container {
  padding: 8px 16px;
  text-align: end;
}
.header {
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 8px #00000015;
  -webkit-box-shadow: 0 2px 8px #00000015;
  background-color: white;
  padding: 0;
  position: fixed;
  z-index: 100;
  width: 100%;
  border-bottom: 1px;
}
.header-logo {
  object-fit: contain;
  height: 48px;
}
.footer {
  flex-shrink: 0;
  background-color: #fff;
  padding: 24px;
}
.app {
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  padding-top: 64px;
  background-color: #f0f2f5;
  min-height: 100vh;
  /* // min-width: 98w; */
}
.wrapper__main {
  flex: 1 0 auto;
  padding: 8px;
  margin: auto;
}
@media (max-width: 575.98px) {
  .wrapper__main {
    width: 95%;
  }
  .wrapper__section {
    padding: 24px 16px;
  }
  .header {
    padding: 0 2%;
  }
}
@media (max-width: 991.98px) and (min-width: 576px) {
  .header {
    padding: 0 4%;
  }
  .wrapper__main {
    width: 90%;
  }
  .wrapper__section {
    padding: 24px;
  }
}
@media (max-width: 1199.98px) and (min-width: 992px) {
  .header {
    padding: 0 6%;
  }
  .wrapper__main {
    width: 75%;
  }
  .wrapper__section {
    padding: 24px 32px;
  }
}
@media (min-width: 1200px) {
  .header {
    padding: 0 8%;
  }
  .wrapper__main {
    width: 70%;
  }
  .wrapper__section {
    padding: 32px 40px;
  }
}
